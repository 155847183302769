import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LffPopAlertService } from './lff-popalert.service';
import { LffStateService } from './lff-state.service';

/**
 * Impedisce l'accesso alle route della shell se non si è ADMIN
 */
@Injectable()
export class AdminGuardService implements CanActivate {
  constructor(private router: Router, private stateService: LffStateService, private popAlertService: LffPopAlertService) {}

  canActivate(): boolean {
    // Andrebbe fatto un controllo lato server (che comunque fa già i suoi controlli)
    if (this.stateService.state?.profilo?.admin) {
      return true;
    }

    this.popAlertService.showErrorMessage('Non possiedi le autorizzazioni necessarie');
    this.router.navigate(['./']);
    return false;
  }
}
