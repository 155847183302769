import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class PwaService {
  installPrompt;
  isUpdateAvailable: boolean;

  constructor(private swUpdate: SwUpdate, private appRef: ApplicationRef) {
    // -- Gestione installazione

    window.addEventListener('beforeinstallprompt', (event) => {
      this.installPrompt = event;
    });

    window.addEventListener('appinstalled', () => {
      this.installPrompt = null;
    });

    // -- Gestione nuovi aggiornamenti

    if (environment.production && 'serviceWorker' in navigator) {
      console.log('Controllo aggiornamenti attivo');

      const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
      const everySixHours$ = interval(0.5 * 60 * 60 * 1000); // 30min

      concat(appIsStable$, everySixHours$).subscribe((_) => {
        console.log('Controllo aggiornamenti');

        this.swUpdate.checkForUpdate();
      });
    }
  }

  installPwa(): void {
    if (!this.installPrompt) {
      return;
    }

    this.installPrompt.prompt();
  }

  subscribeForUpdates(): void {
    this.swUpdate.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading nuova versione`);
          break;

        case 'VERSION_READY':
          console.log(`Aggiornamento disponibile`);
          this.isUpdateAvailable = true;
          break;

        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
  }

  installUpdates(): void {
    document.location.reload();
  }
}
