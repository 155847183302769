import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * Servizi globali per l'applicazione.
 * Sono contenuti i servizi comuni tra i diversi moduli.
 */
@Injectable()
export class LffCoreService {
  private _isRunning$: Subject<boolean>;

  constructor() {
    this._isRunning$ = new Subject<boolean>();
  }

  // Gestione per il loading delle chiamate HTTP
  get isHttpRunning$(): Observable<boolean> {
    return this._isRunning$.asObservable();
  }

  startHttpRunning() {
    this._isRunning$.next(true);
  }

  stopHttpRunning() {
    this._isRunning$.next(false);
  }
}
