import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

export type IHttpParams = object | never;

@Injectable()
export class LffHttpService {
  constructor(private http: HttpClient) {}

  get(action: string, params?: IHttpParams): Observable<any> {
    const newParams = this.processGetParams(params);

    return this.http
      .get(action, {
        params: newParams,
      })
      .pipe(share());
  }

  post(action: string, payload: any): Observable<any> {
    return this.http.post(action, payload).pipe(share());
  }

  put(action: string, payload: any): Observable<any> {
    return this.http.put(action, payload).pipe(share());
  }

  delete(action: string): Observable<any> {
    return this.http.delete(action).pipe(share());
  }

  fileByGet(action: string, params?: IHttpParams): Observable<any> {
    const newParams = this.processGetParams(params);

    return this.http
      .get(action, {
        params: newParams,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(share());
  }

  fileByPost(url: string, body: any): Observable<any> {
    return this.http
      .post(url, body, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(share());
  }

  fileByPut(url: string, body: any): Observable<any> {
    return this.http
      .put(url, body, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(share());
  }

  private processGetParams(params?: IHttpParams): { [param: string]: string | string[] } {
    // newParams è di tipo oggetto any e non HttpParams così poi http gestisce i parametri nativamente con i suoi standard.
    const newParams: { [param: string]: string | string[] } = {};

    if (!params) {
      params = {};
    }

    // Vengono esclusi dalla querystring i parametri con valore a null.
    Object.entries(params)
      .filter((p) => typeof p[1] !== 'function' && p[1] !== null && p[1] !== undefined)
      .forEach((p) => {
        newParams[p[0]] = p[1];
      });

    // Aggiunge alla querystring una data per non avere la cache
    const key = '_';
    newParams[key] = new Date().valueOf().toString();

    return newParams;
  }
}
