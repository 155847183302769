import { environment } from '../../environments/environment';

export const AppConfig = {
  apiUrl: environment.apiUrl,
  version: '1.29.0',
  isTest: !environment.production && environment.isTest,
  defaultStorageMode: 'local',
  gtag: 'G-Y7WVLZCW8K',
  googleClientId: '964573718764-39fcvqn7n1ick67a9gktamocosi77q83.apps.googleusercontent.com',
};
