import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { LffBaseCmpDirective } from '../components/lff-base.component';
import { LffPopAlertService, MessageTypes } from '../services/lff-popalert.service';

export interface Message {
  severity: string;
  title: string;
  message: string;
  icon: string;
}

@Component({
  selector: 'lff-popalert',
  templateUrl: './lff-popalert.component.html',
  styleUrls: ['lff-popalert.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LffPopAlertComponent extends LffBaseCmpDirective implements OnInit {
  messages: Message[] = [];

  constructor(private popAlertService: LffPopAlertService) {
    super();
  }

  ngOnInit() {
    this.popAlertService.message$.pipe(takeUntil(this.destroyed$)).subscribe((response) => {
      const severity = response.severity;
      let title: string;
      let icon: string;
      switch (response.severity) {
        case MessageTypes.Information.Severity:
          title = 'MSG.TITLE.INFO';
          icon = 'info';
          break;
        case MessageTypes.Warning.Severity:
          title = 'MSG.TITLE.WARNING';
          icon = 'exclamation-triangle';
          break;
        case MessageTypes.Error.Severity:
          title = 'MSG.TITLE.ERROR';
          icon = 'angry';
          break;
        case MessageTypes.Success.Severity:
          title = 'MSG.TITLE.SUCCESS';
          icon = 'check';
          break;
      }

      const message = this.decodeMessage(response.message);
      this.messages = [...this.messages, { severity, title, message, icon }];

      setTimeout(() => {
        this.messages.shift();
      }, 5000);
    });
  }

  decodeMessage(message: string): string {
    // tslint:disable-next-line:quotemark
    message = message.replace('\u0027', "'");
    message = message.replace('\r\n', ' ');
    return message;
  }
}
