import { Component } from '@angular/core';
import { PwaService } from '@core/services/pwa-service.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(private pwaService: PwaService) {
    this.pwaService.subscribeForUpdates();
  }
}
