import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LffStorageService } from './lff-storage.service';

interface AppState {
  profilo: LoginDto;
  profiloVoucher: EdizioneVoucherLoginDto;
  filtroIscrizioni: IscrizioniFilter;
  filtroProgrammazioni: ProgrammazioneFilter;
}

type EventType = keyof AppState;

@Injectable()
export class LffStateService implements OnDestroy {
  get state(): AppState {
    const result = this.storageService.get(ConfigSession.appState);
    return JSON.parse(result) || {};
  }

  private _evts: {
    [_key: string]: BehaviorSubject<unknown>;
  } = {};

  constructor(private storageService: LffStorageService) {}

  ngOnDestroy(): void {
    for (const _key in this._evts) {
      // Controllo sulla proprietà per TsLint check: for (... in ...) statements must be filtered with an if statement
      if (this._evts.hasOwnProperty(_key)) {
        this._evts[_key].complete();
        (this._evts[_key] as unknown) = undefined;
      }
    }
  }

  dispatch(action: EventType, value: any): void {
    const state = this.state;
    state[action] = value;

    this.storageService.set(ConfigSession.appState, JSON.stringify(state));
    const _key = action.toString();
    if (_key in this._evts) {
      this._evts[_key].next(state[action]);
    }
  }

  // TODO definire any
  // get$<T>(eventName: EventType, destroy$: Observable<void>): Observable<T> {
  get$<T>(eventName: EventType, destroy$: Observable<void>): Observable<any> {
    const _key = eventName.toString();
    if (!(_key in this._evts)) {
      if (this.state[_key]) {
        this._evts[_key] = new BehaviorSubject<T>(this.state[_key]);
      } else {
        this._evts[_key] = new BehaviorSubject<T>(undefined);
      }
    }

    return (this._evts[_key].asObservable() as Observable<T>).pipe(
      filter((t) => t !== undefined),
      takeUntil(destroy$)
    );
  }
}
